@font-face {
  font-family: 'NunitoRegular';
  src: local('NunitoRegular'), url(./Nunito-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'LibreBaskervilleRegular';
  src: local('LibreBaskervilleRegular'), url(./LibreBaskerville-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('./Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('./Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('./Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}